export const dance1 = {
    contents: `<div>
                        <p>댄스 클래스는 전 세계적으로 사랑받는 <span>K-Pop 음악</span>을 중심으로 진행되는
                            다이나믹하고 에너지 넘치는 댄스 수업입니다.</p>

                        <p>
                            이 클래스는 <span>초보자부터 중급자</span>까지 다양한 수준의 참가자를 환영하며,
                            누구나 쉽게 따라 할 수 있도록 구성되어 있습니다.
                        </p>

                        <p>
                            전문 강사의 지도 아래 K-Pop의 <span>최신 트렌드 안무</span>를 배우고,
                            유연성과 균형을 강화하며 리듬 감각을 향상시킬 수 있습니다.
                            뿐만 아니라, 각 곡의 컨셉과 퍼포먼스 스타일을 체계적으로 분석하여
                            춤 동작뿐만 아니라 <span>K-Pop 특유의 표현력</span>을 익히는 데도 중점을 둡니다.
                        </p>

                        <p>
                            수업은 개인과 팀워크를 균형 있게 발전시킬 수 있도록 설계되어 있습니다.
                            팀 단위로 협업하여 퍼포먼스를 완성해가는 과정에서
                            <span>자신감과 성취감</span>을 느낄 수 있습니다.
                        </p>

                        <p>
                            또한, 정기적인 리허설과 소규모 공연을 통해 자신의 실력을 뽐낼 기회도 제공합니다.
                            K-Pop 댄스 클래스는 단순히 춤을 배우는 것을 넘어,
                            자신을 표현하고 새로운 친구들을 만나는 <span>특별한 경험</span>을 제공합니다.
                        </p>

                        <p>
                            음악과 함께 신나게 움직이며 스트레스를 해소하고,
                            즐겁게 댄스 실력을 키워보세요!
                        </p>
                    </div>`,
    images: [
        '/dance/main.jpeg',
        '/dance/img1.jpeg',
        '/dance/img2.jpeg',
        '/dance/img3.jpeg',
    ]
};
