// export const kids1 = {
//   contents: `<div>
//             <p>드림하이 키즈스쿨은 아이들의 <span>자신감</span>과 <span>표현력</span>을 키우기 위  <span>키즈 댄스</span>, <span>키즈 모델</span>, <span>키즈 스피치</span> 프로그램을 제공합니다. </p>
//             <p>키즈 댄스는 <span>리듬 감각</span>과 <span>협동심</span>을, 키즈 모델은 워킹과 포즈를 통해 자연스러운 표현력을, 키즈 스피치는 말하기 연습을 통해 <span>의사소통 능력</span>을 길러줍니다. </p>
//             <p>아이들이 다양한 활동을 통해 <span>창의력</span>과  개성을 마음껏 발휘할 수 있도록 돕는 교육 과정입니다.</p>
//           </div>`,
//   images: [
//     '/kids/main.jpg',
//   ]
// };

export const kids1 = {
  contents: `<div> 
            <p>키즈 댄스 반은 아이들이 즐겁게 춤을 배우며<span>표현력</span>과  <span>자신감</span>을 키울 수 있도록 설계된 프로그램으로, 기본적인 리듬 <span>감각</span>과 <span>동작</span>을 익히는 것부터 시작해 <span>창의적인 안무</span>와 <span>팀워크</span>를 배우는 과정입니다. </p>
            <p>전문 강사진의 지도 아래 아이들의 연령과 수준에 맞춘 <span>맞춤형 커리큘럼</span>을 제공하며, <span>유연성</span>과 <span>신체 발달</span>을 돕는 활동을 통해 <span>건강한 성장</span>에 기여합니다. </p>
            <p>더불어 <span>무대 경험</span>과 <span>발표 기회</span>를  통해 성취감을 느끼며춤을 통한 자기 <span>표현 능력</span>을 자연스럽게 발전시킬 수 있는 과정입니다.</p>
          </div>`,
  images: [
    '/kids/1/1.JPG',
    '/kids/1/2.JPG',
    '/kids/1/3.jpg',
    '/kids/1/4.jpg',
  ]
};

export const kids2 = {
  contents: `
    <div>
            <p>키즈 모델 반은 어린이들이 <span>모델</span>로서의 <span>기본적</span>인 자세와  표현력 배울 수 있는 프로그램 입니다.</p>
            <p>전문적인 포즈, 표정 연기, 카메라 앞에서의 <span>자신감</span>을 기를 수 있는 다양한 실습을 통해 아이들은 <span>모델</span>로서 필요한 기초를 탄탄히 다질 수 있습니다. </p>
            <p>또한, 체형 관리와 스타일링, 워킹 연습을 포함한 <span>맞춤형 교육</span>을 통해 어린이 개개인의 <span>특성</span>과 <span>매력</span>을 살리며,실제 촬영과 패션쇼 경험을 통해 무대 경험을 쌓고  자신감을 키울 수 있습니다. </p>
            <p>이 과정은 아이들이 무대에서 자연스럽게 빛날 수 있도록 돕고, <span>자신감</span>을 키우며 모델로서의 가능성을 펼칠 수 있는 <span>기회</span>를 제공합니다.</p>
          </div>
    `,
  images: [
    '/kids/2/1.jpg',
    '/kids/2/2.jpg',
    '/kids/2/3.jpg',
    '/kids/2/4.jpg',
  ]
};

export const kids3 = {
  contents: `
    <div> 
            <p>키즈 스피치 반은 어린이들이 <span>자신</span>의 생각을 명확하  <span>자신감</span> 있게 표현할 수 있도록 돕는 프로그램입니다. </p>
            <p>이 과정에서는 발음 교정, 발성 연습, 올바른 목소리 톤 사용 등 기본적인 말하기 기술을 배우며  아이들의 <span>창의력</span>과 <span>언어 표현력</span>을 키울 수 있습니다. </p>
            <p>다양한 상황에 맞춰 이야기하기, 발표 연습, 즉석 연설 등을  통 아이들은 자연스럽게 사람들 앞에서 <span>발표</span>하는 능력을 기르고 자신의 의견을 자신 있게 전달하는 방법을 익힙니다. </p>
            <p>이를 통해 어린이들은 <span>사회성 향상</span>과  <span>자신감</span> 있는 대화 능력 기를 수 있습니다.</p>
          </div>
    `,
  images: [
    '/kids/3/1.jpg',
    '/kids/3/2.jpg',
    '/kids/3/3.jpg',
    '/kids/3/4.jpg',
    '/kids/3/5.jpg',
    '/kids/3/6.jpg',
    '/kids/3/7.jpg',
    '/kids/3/8.jpg',
  ]
};
