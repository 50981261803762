// export const beauty1 = {
//     contents: `
//         <div>
//             <p>우리 학원의 미인대회 프로그램은 <span>미스그린코리아</span>, <span>미스코리아</span>, <span>미스인터콘티넨탈</span>을 목표로 한 종합 교육 과정입니다. </p>
//             <p>미스그린코리아는 환경 의식을 갖춘  <span>아름다움을</span>, 미스코리아는 <span>지성</span>, <span>인성</span>, <span>개성</span>을 갖춘 인재를, 미스인터콘티넨탈은 글로벌 리더로서 <span>국제적 소통 능력</span>을 갖춘 인재를 선발하고 육성합니다. </p>
//             <p>참가자들은 각 대회의 목적에 맞춘 훈련을 통해 <span>내적·외적 성장</span>을 이루며 사회적 책임감을 갖춘 <span>진정한 아름다움</span>을 추구하게 됩니다.</p>
//         </div>
//     `,
//     images: [
//         '/beauty/main.png'
//     ]
// };

export const beauty1 = {
    contents: `
        <div>
            <p>미스코리아는 대한민국을 대표하는 미와 재능의 플랫폼으로, 지성과 개성, 사회적 책임감을 겸비한 인재를 발굴하는 현대적인 미인 선발 대회입니다.</p>
            <p>외적 아름다움뿐 아니라 참가자의 자기표현력, 창의성, 리더십, 그리고 사회적 영향력을 종합적으로 평가하며, 글로벌 무대에서도 활약할 수 있는 역량을 갖춘 여성을 선발합니다.</p>
            <p>최근에는 전통적 미의 기준을 넘어 다양성과 개성을 존중하며, 참가자들이 환경, 사회 공헌, 문화 교류 등 다양한 활동에 참여해 긍정적인 변화를 이끄는 롤 모델로 성장할 수 있도록 지원합니다.</p>
            <p>미스코리아는 단순한 대회가 아닌, 현대 여성이 가진 잠재력을 발휘하고 글로벌 영향력을 넓히는 장으로 자리매김하고 있습니다.</p>
        </div>
    `,
    images: [
        '/beauty/1/1.jpg',
        '/beauty/1/2.jpg',
        '/beauty/1/3.jpeg',
    ]
};

export const beauty2 = {
    contents: `
        <div>
            <p>미인대회는 외적 아름다움뿐 아니라 개성과 재능, 지적 능력을 겸비한 인재를 발굴하는 경쟁의 장으로, 참가자들은 각종 활동을 통해 자기표현력과 자신감을 키우는 기회를 갖게 됩니다.</p>
            <p>대회마다 독특한 주제와 기준을 바탕으로, 패션, 문화, 환경, 인권 등 다양한 분야에서 참가자들의 역량과 메시지를 평가하며, 이를 통해 각 대회가 추구하는 가치를 구현합니다.</p>
            <p>참가자들은 대회를 통해 단순한 미의 경쟁을 넘어 공동체에 긍정적인 영향을 미칠 수 있는 리더로 성장합니다.</p>
            <p>세계 각국에서 열리는 다양한 미인대회는 참가자들이 문화 교류, 글로벌 네트워크 형성, 사회 공헌 활동에 참여할 기회를 제공하며, 대중 앞에서 자신의 이야기를 전하고 공감을 이끌어내는 플랫폼으로 자리 잡고 있습니다.</p>
        </div>
    `,
    images: [
        '/beauty/2/1.jpg',
        '/beauty/2/2.png',
        '/beauty/2/3.png',
        '/beauty/2/4.jpg',
        '/beauty/2/5.jpg',
        '/beauty/2/6.jpg',
    ]
};

export const beauty3 = {
    contents: `
        <div>
            <p>지역홍보대사 대회는 각 지역의 특산물과 문화를 알릴 대표 인재를 선발하는 행사입니다.</p>
            <p>영양고추아가씨나 영천포도피플 같은 대회는 지역 특산물을 주제로 하여, 참가자들이 해당 지역의 매력을 홍보할 기회를 제공합니다.</p>
            <p>이 대회는 지역의 전통과 문화를 배우고, 이를 대중에게 효과적으로 전달할 수 있는 능력을 평가합니다.</p>
            <p>선발된 홍보대사들은 지역 행사, 캠페인, 축제에 참여하며 지역 브랜드 가치를 높이는 역할을 합니다.</p>
            <p>대회를 통해 지역 경제를 활성화하고, 관광객 유치와 지역 이미지 향상에 기여하는 중요한 역할을 하게 됩니다.</p>
            <p>지역홍보대사 대회는 지역 사회와 외부 방문객을 연결하는 중요한 문화적 이벤트로 자리 잡고 있습니다.</p>
        </div>
    `,
    images: [
        '/beauty/3/1.png',
        '/beauty/3/2.png',
    ]
};
