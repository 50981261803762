export const host1 = {
    contents: `<p>
                    예식전문 사회자 반은 결혼식, 약혼식 등 다양한 예식 행사를 전문적으로 진행할 수 있는 능력을 기르기 위한
과정으로, 품격 있는 진행력과 자연스러운 무대 매너를 갖추는 데 초점을 맞추고 있습니다.
                </p>
                <p>
                   예식 진행의 기본 구조와 상황별 응대 방법을 체계적으로 학습하며, 유쾌하고 따뜻한 분위기를 이끌어내는 화법과 청
중을 사로잡는 스킬을 배웁니다. 실전 시뮬레이션과 스크립트 작성 훈련을 통해 다양한 예식 상황에 유연하게 대처할
수 있는 전문 사회자로 성장할 수 있도록 돕는 프로그램입니다.
                </p>
               `,
    vie: `<ul class="cdoe">
        <li>
            <h3>예식 업체 연계데뷔</h3>
            <p>우수 교육생 사회자 활동 기회 제공</p>
        </li>
        <li>
            <h3>나만의 시나리오 작성</h3>
            <p>사회자에게 가장 중요한 시나리오 제공 및 자신만의 시나리오 완성</p>
        </li>
        <li>
            <h3>카메라 모니터링 교육</h3>
            <p>카메라를 활용한 교육을 통해 표정, 제스처, 눈빛 까지 디테일한 코칭</p>
        </li>
    </ul>`,
    images: [
        '/host/2/1.jpg',
        '/host/2/2.jpg',
        '/host/2/3.jpg',
        '/host/2/4.jpg'
    ]
};