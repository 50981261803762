export const speech1 = {
    contents: `
        <p>아나운서 수업은 방송의 기본기부터 심화 기술까지 다루며, 정확한 발음과 발성, 명확한 의사 전달, 세련된 방송 이미지 구축을 목표로 합니다.</p>
        <p>또렷하고 신뢰감을 주는 목소리를 위해 발음·발성 훈련과 안정적인 호흡법을 배우고, 뉴스 대본 및 라디오 스크립트 리딩, 효과적인 억양 조절과 표현력 향상에 집중합니다.</p>
        <p>카메라 앞 자연스러운 표정·자세, 마이크 사용법, 방송 스킬을 훈련하며 돌발 상황 대처 능력과 메시지 구성력을 강화합니다.</p>
        <p>프로페셔널한 이미지 메이킹, 말투·제스처 등 세련된 방송 태도 훈련으로 발표·인터뷰 실력을 높여, 뉴스앵커, 아나운서, 기상캐스터 등 전문 방송인으로 성장할 수 있는 교육 과정입니다.</p>
    `,
    vie: `DBSA 대구야구소프트볼협회 장내 아나운서, 영남일보 인턴 아나운서 등 다양한 방송 캐스팅 기회 제공
    `,
    images: [
        '/speech/2/1.png',
        '/speech/2/2.jpg'
    ]
};

export const speech2 = {
    contents: `
        <p>크리에이터·MC 수업은 온라인 콘텐츠와 방송 진행 능력을 갖춘 멀티플레이어를 양성하는 교육 과정입니다.</p>
        <p>자연스럽고 매력적인 진행을 위해 발음·발성 훈련, 전달력 있는 말하기, 유머와 순발력을 활용한 진행법을 배우며, 시청자와의 소통 강화 방법도 다룹니다.</p>
        <p>라이브와 녹화 방송에 모두 적합한 퍼포먼스 연습을 통해 크리에이터와 MC로서의 역량을 균형 있게 성장시킬 수 있습니다.</p>
        <p>자신만의 콘텐츠로 대중과 소통하거나 전문적인 진행 기술을 배우고 싶은 분, 크리에이터와 MC 분야에 도전하려는 분들에게 최적화된 교육 과정입니다.</p>
    `,
    vie: `
        방송 리포터,
        컨텐츠 진행자,
        행사 진행자 등 캐스팅 기회 제공
    `,
    images: [
        '/speech/3/1.png',
        '/speech/3/2.png',
        '/speech/3/3.png',
        '/speech/3/4.png',
        '/speech/3/5.png',
    ]
};

export const speech3 = {
    contents: `
        <p>취업·면접 컨설팅 수업은 구직자가 원하는 직무와 기업에 효과적으로 어필할 수 있도록 면접 기술과 취업 전략을 체계적으로 학습하는 과정입니다.</p>
        <p>자기소개서와 이력서 작성부터 면접에서의 자신감 있는 표현과 자세, 비언어적 커뮤니케이션 능력 향상까지 실전 중심의 교육을 제공합니다.</p>
        <p>모의 면접을 통해 다양한 질문에 맞춤형 답변을 준비하고 실전 감각을 기릅니다.</p>
        <p>최신 채용 트렌드와 산업 동향을 반영한 취업 정보 제공, 온라인 면접 준비, 그룹 디스커션 및 프레젠테이션 스킬 훈련 등 필수적인 요소들을 다룹니다.</p>
    `,
    vie: `
        입시, 취업, 공무원(경찰, 소방),
        대기업, 공기업 등 취업·면접 컨설팅
    `,
    images: [
        '/speech/4/1.jpg',
        '/speech/4/2.jpg',
        '/speech/4/3.jpeg',
        '/speech/4/4.jpeg',
    ],
    subTitle: '주요 내용',
};

export const speech4 = {
    contents: `
        <p>브리핑·프레젠테이션 코칭 수업은 명확하고 설득력 있는 발표 능력을 키우기 위한 교육 과정으로, 비즈니스 브리핑부터 대중 프레젠테이션까지 다양한 상황에 적합한 기술을 체계적으로 가르칩니다.</p>
        <p>핵심 메시지 전달, 청중의 관심을 끄는 스토리텔링, 논리적인 내용 구성 및 슬라이드 디자인 전략을 학습하며, 시각 자료를 활용해 발표의 임팩트를 극대화하는 방법을 익힙니다.</p>
        <p>발음·발성·톤 조절 등 언어적 요소와 함께 시선 처리, 제스처, 표정 등 비언어적 커뮤니케이션 스킬을 다룹니다.</p>
        <p>실전 연습을 통해 긴장을 줄이고 자신감을 높이는 방법을 배우며, 발표 후 질의응답 시간에 효과적으로 대응하는 기술까지 훈련합니다.</p>
    `,
    images: [
        '/speech/5/1.png',
    ],
};

export const speech5 = {
    contents: `
        <p>발성·발음·사투리 교정 수업은 또렷한 발음과 안정적인 발성을 통해 자연스럽고 신뢰감을 주는 말하기 능력을 키우는 전문 과정입니다.</p>
        <p>올바른 호흡법과 발성 기초로 풍부한 음성을 만드는 법을 배우고, 자음과 모음 발음 훈련을 통해 전달력을 강화하며, 표준 발음에 가까운 자연스러운 말하기를 습득하도록 돕습니다.</p>
        <p>다양한 대화와 스피치 실습으로 실생활과 비즈니스에 적합한 발음을 연습하고, 녹음 및 피드백을 통해 개인의 발음 습관을 분석·개선하는 맞춤형 코칭을 제공합니다.</p>
    `,
    vie: `
        목소리 컨설팅,
        발음 교정,
        사투리 교정,
        스몰 토킹, 리더 스피치, 논리정연 스피치, 설득 스피치
    `,
    images: [
        '/speech/6/1.jpg',
        '/speech/6/2.jpg',
        '/speech/6/3.jpg',
        '/speech/6/4.jpg',
        '/speech/6/5.jpg',
    ],
    subTitle: '주요 내용',
};

export const speech6 = {
    contents: `
        <p>스피치 강사 양성교육은 전문 스피치 강사를 목표로 효과적인 강의와 발표 능력을 체계적으로 배우는 종합 과정입니다.</p>
        <p>발음·발성 훈련, 목소리 관리, 스토리텔링 기법, 청중과의 소통 등 핵심 스피치 기술을 익히며, 구조화된 커리큘럼과 수업 진행 방법을 학습합니다.</p>
        <p>실제 강의 환경을 시뮬레이션해 긴장 관리와 청중을 끌어들이는 강의 기법을 연습하며, 다양한 연령대와 수준에 맞는 맞춤형 수업 능력을 개발합니다.</p>
    `,
    vie: `
        <p>지자체, 기업 등 다양한 기관 강의 출강 강사양성 교육 진행</p>
    `,
    images: [
        '/speech/7/1.png',
        '/speech/7/2.png',
        '/speech/7/3.jpg',
    ],
    subTitle: '주요 내용',
};
