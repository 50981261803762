export const model1 = {
    contents: `<div>
            <p>모델 수업은 <span>시니어모델</span>과  <span>프로모델</span>을 위한 맞춤형 교육 과정입니다. </p>
            <p>시니어모델은 나이에 상관없이 자신만의  <span>매력</span>을   발산하며 활동할 수 있도록  <span>패션</span>, <span>워킹</span>, <span>포즈</span> 등을 배웁니다. </p>
            <p>프로모델은 <span>패션쇼</span>와 <span>촬영</span> 등 다양한 분야에서  활동할 수 있는 전문 모델로 성장할 수 있도록  <span>실무 중심</span>의 <span>훈련</span>을 제공합니다. </p>
            <p>두 과정 모두 <span>개성</span>과 <span>자신감</span>을 키워 전문 모델로서의  역량을 극대화할 수 있도록 돕습니다.</p>
          </div>`,
    images: [
      '/model/main.jpeg',
      '/model/img12.jpeg',
    ]
};

export const model2 = {
    contents: `<div>
            <p>시니어모델 반은 <span>중·장년층</span>을 대상으로 패션 및 광고 모델로서의 새로운 가능성을 열어가는 과정으로, <span>개성</span>과
              <span>연륜</span>이 돋보이는 워킹, 포즈, 표정 연기를  <span>체계적</span>으로 배웁니다.
            </p>
            <p>시니어만의 독창적인 매력을 발산할 수 있도록 각 분야의  전문 강사진이 <span>기초</span>부터 실전까지 세심하게 지도하며, 런웨이, 사진 촬영, 광고 연출 등
              다양한 상황에 맞는 실습을 통해<span>자신감</span>을 키웁니다.</p>
            <p>더불어 건강하고 균형 잡힌 몸매 관리 및 이미지 메이킹을  통해 시니어 <span>모델</span>로서의 경쟁력을 강화하며, 새로운 <span>도전</span>을 통해 꿈을
              실현할 수 있는  기회를 제공합니다.</p>
          </div>`,
    images: [
      '/model/1/1.jpg',
      '/model/1/2.jpg',
      '/model/1/3.jpg',
      '/model/1/4.JPG',
      '/model/1/5.jpg',
      '/model/1/6.jpg',
      '/model/1/7.jpg',
      '/model/1/8.jpg',
      '/model/1/9.jpg',
      '/model/1/10.jpg'
    ] 
};

export const model3 = {
    contents: `
    <div>
            <p>프로 모델 반은 대학입시, 패션쇼, 광고, 화보 등 <span>다양한 모델 활동</span>을 목표로 하는 10~20대 들을 위한 <span>전문 과정</span>으로,
              글로벌 스탠다드에 맞춘
              워킹, 포즈, 연기 등 모델로서의 <span>핵심기술</span>을 체계적으로 익힙니다. </p>
            <p>실제 무대를 연상케 하는 실습 환경에서 <span>실전 감각</span>을 키우고, 개성과 경쟁력을 살리는 <span>이미지 메이킹</span> 및 <span>스타일링
                교육</span>을 병행하여 패션업계 뿐 아니라 각 분야에서
              요구하는 모델의 이미지로 적응할 수 있게 합니다.</p>
            <p>또한, 오디션 준비와 포트폴리오 제작을 지원하며, 실제 경험이 <span>풍부한 모델강사</span>와 <span>업계 전문가</span>들의 멘토링을 통해 모델로서의
              경력을 성공적으로 시작할 수 있는 기반을
              제공하며, <span>프로패셔널한 모델</span>로 성장할 수 있도록 돕습니다.</p>
            <p>- <span>basic</span> - </p>
            <p>자세교정, 체형관리, 워킹교정, 기초워킹, 기본포즈, 무대의이해 등의 수업을 통해
              모델로서의 입문과정을 배웁니다.</p>
            <p>- <span>modeltainer</span> - </p>
            <p>모델기초과정을 마친 모델들의 심화과정으로 모델대회 준비 및 캐스팅에 바로 준비가능한 모델이 되는 과정입니다. 무대매너, 의상연출, 모델연기, 바디밸런스, 패션포즈, 스
              피치등을 통해 패션모델로서 광고, 연기, 크리에이터로 활동범위를 넓힐 수 있습니다.</p>
          </div>
    `,
    images: [
      '/model/2/1.jpg',
      '/model/2/2.jpg',
      '/model/2/3.jpg',
      '/model/2/4.jpg',
      '/model/2/5.jpg',
      '/model/2/6.jpg',
    ] 
};

export const model4 = {
    contents: `
    <div>
            <p>취미반 워킹 클래스는 <span>개인</span>의 <span>목표</span>에 맞춰자세교정, 체형진단,  걸음걸이 교정등으  특별 하거나 <span>일상적인 자리</span>에서도  자신을  <span>돋보일수 있는</span> 워킹자세 교정 프로그램입니다. </p>
            <p>이 클래스에서는 올바른 <span>자세</span>와 <span>균형</span>을 배우고, 자연스럽고 우아한 <span>워킹 스타일</span>을 개발할 수 있습니다.</p>
            <p>단기적으로 또는 지속적으로 <span>부담 없이</span> 참여할 수 있으며, 걸음걸이에 자신이 없거나 <span>매력적인 워킹</span>을 원하시는 분들에게 추천합니다. </p>
            <p>다양한 수준에 <span>맞춘 수업</span>을 통해 누구나 쉽게 따라할 수 있으며, <span>자신감</span>을 얻고 일상에서 더 <span>매력적인 워킹</span>을 실현할 수 있도록 돕습니다.</p>
          </div>
    `,
    images: [
      '/model/3/1.png'
    ] 
};

export const model5 = {
    contents: `<div> 
            <p><span>포토포즈반</span>은 개인의 체형과 이미지의 <span>장단점</span>을 <span>분석</span>하여 포즈 연구와  모니터링을 통해<span>섬세한 표정</span>과 포즈를 다듬어 드리는 프로그램입니다. </p>
            <p>8시간 이상의 교육을 통해 <span>포즈</span>와 <span>표정</span>을 완성한 후, 실전 촬영을 진행하고 <span>결과물</span>을 바탕으로  포트폴리오를 제작할 수 있습니다. </p>
            <p>또한, 이미지 컨설턴트 전문가가 <span>퍼스널 컬러</span>와 얼굴형에 맞는 <span>헤어</span>와 <span>메이크업</span>을 제공하며, 패션모델 출신의 <span>센터장</span>과 <span>원장님</span>이 직접 체형 관리와  <span>포즈 수업</span>을 진행하 전문적인 트레이닝을 제공합니다.</p>
          </div>`,
    images: [
      '/model/4/1.jpg',
      '/model/4/2.jpg',
      '/model/4/3.jpg',
      '/model/4/4.jpg',
      '/model/4/5.jpg',
      '/model/4/6.jpg',
    ] 
};